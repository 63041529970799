import { Parser, type Value } from 'expr-eval'
import { resolveRealPath, useManagerFormValue, type INodeField } from '@manager'
import { extractVariables, resolveCalculatedVariable } from './utils'
import type { MaybeRef, WatchStopHandle } from 'vue'

export const useFormula = () => {
  const dayjs = useDayjs()
  const { formValue } = useManagerFormValue()

  const $dateDiff = (a: Value, b: Value) => {
    const startDate = a ? dayjs(a as Date | string).add(1, 'days') : a
    const endDate = dayjs(b as Date | string)
    return endDate.formatDiff(startDate)
  }
  const $monthDiff = (a: Value, b: Value) => {
    const startDate = dayjs(a as Date | string).startOf('day')
    const endDate = dayjs(b as Date | string).endOf('day')
    return endDate.diff(startDate, 'month', true)
  }

  const stopWatchers: WatchStopHandle[] = []

  const createFormula = (
    formula: MaybeRef<string | null | undefined>,
    parentPath: string | undefined,
  ) => {
    const parser = new Parser()
    const resolvedFormula = computed(() => {
      let _formula = unref(formula)

      if (_formula) {
        const variables = extractVariables(_formula)

        for (const variable of variables) {
          let path = variable

          if (parentPath) {
            try {
              path = resolveRealPath(variable, parentPath)
            } catch (error) {
              console.error(error)
            }
          }
          _formula = _formula.replaceAll(
            variable,
            resolveCalculatedVariable(path),
          )
        }
      }

      return _formula
    })
    const parsed = computed(() => {
      try {
        // @ts-expect-error
        return parser.parse(resolvedFormula.value)
      } catch (e) {
        return null
      }
    })

    const evaluated = ref<any>(null)

    const stop = watch(
      [parsed, formValue],
      ([parsed, formValue]) => {
        if (!parsed || !formValue) {
          evaluated.value = null
          return
        }

        try {
          evaluated.value =
            parsed.evaluate({
              ...formValue,
              $dateDiff,
              $monthDiff,
            }) ?? null
        } catch (e) {
          evaluated.value = null
        }
      },
      { immediate: true, deep: true },
    )

    stopWatchers.push(stop)

    tryOnScopeDispose(() => {
      for (const stop of stopWatchers) {
        stop()
      }
    })

    return { formula: resolvedFormula, evaluated }
  }

  return { createFormula }
}
