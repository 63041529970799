import {
  anyOf,
  createRegExp,
  digit,
  exactly,
  global,
  letter,
  oneOrMore,
  wordChar,
  wordBoundary,
} from 'magic-regexp'

function getVariablesByGroup(
  array: {
    groups: {
      variable: string | undefined
      customVariable: string | undefined
      customVariableFn: string | undefined
    }
  }[],
) {
  // Use reduce to accumulate the variables and customVariables arrays
  return array.reduce<{
    variables: string[]
    customVariables: string[]
    customVariableFns: string[]
  }>(
    ({ variables, customVariables, customVariableFns }, match) => {
      const { groups } = match
      if (groups.variable !== undefined) {
        variables.push(groups.variable)
      }
      if (groups.customVariable !== undefined) {
        customVariables.push(groups.customVariable)
      }
      if (groups.customVariableFunc !== undefined) {
        customVariableFns.push(groups.customVariableFn)
      }
      return { variables, customVariables, customVariableFns }
    },
    { variables: [], customVariables: [], customVariableFns: [] }, // Initial values for the variables and customVariables arrays
  )
}

export function extractVariables(formula: string) {
  // `.`
  const dot = exactly('.')

  // `data[]` | `data[0]`
  const data = exactly('data', '[', digit.times.any(), ']')

  // `FooBar1 | fooBar1`
  const varName = letter.and(oneOrMore(wordChar))

  // `.Foo` | `.data[]`
  const nested = dot.and(anyOf(data, varName))

  // `Foo` | `Foo.Bar` | `Foo.data[]` | `Foo.Bar.data[].Baz.data[].Foo`
  const variable = varName
    .and(oneOrMore(nested.optionally()))
    .groupedAs('variable')

  // `$foo` | `$foo.bar`
  const customVariable = exactly('$', oneOrMore(not.whitespace)).groupedAs(
    'customVariable',
  )

  // `$foo()`
  const customVariableFunc = exactly(
    '$',
    oneOrMore(not.whitespace).before('('),
  ).groupedAs('customVariableFn')

  // Get the variables by groups:
  // `variables` are like `foo`, `foo.bar`, `foo.data[].baz`
  // `customVariables` are like `$value`, `$node`, `$length`, `$isDefined`
  const matches = formula.matchAll(
    createRegExp(customVariableFunc.or(customVariable).or(variable), [global]),
  )

  const { variables } = getVariablesByGroup([...matches])
  return Array.from(new Set(variables))
}

export function resolveCalculatedVariable(variable: string) {
  return variable.replace(/(\w+)$/, 'calculated_$1')
}
